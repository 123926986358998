import api from "./core/api";

const BASE_URL = '/plan';

async function getPlan() {
  return await api.get(BASE_URL);
}

async function updatePlan(code) {
  return await api.post(BASE_URL, {code});
}

async function charge(chargeId) {
  return await api.post(BASE_URL + '/charge', {chargeId});
}

const plan = {
  getPlan: getPlan,
  updatePlan: updatePlan,
  charge: charge
};

export default plan;
