import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors";
import fonts from "../../styles/fonts";

const StyledButton = styled.button`
  ${fonts.montserratBold};
  font-size: 12px;
  line-height: 16px;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: auto;
  outline: none;

  color: ${colors.textGrey};
  background: ${colors.greyDark};
  border: 1px solid ${colors.greyDark};
  
  &:hover {
    color: ${colors.textGrey};
    background: ${colors.greyDark};
    border-color: ${colors.greyDark};
  }
`;

const ButtonDisabled = ({children}) => {
  return (
    <StyledButton disabled>
      {children}
    </StyledButton>
  );
};

export default ButtonDisabled
