import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import ButtonGreen from "../Core/ButtonGreen"
import breakpoints from "../../styles/breakpoints"
import ButtonDisabled from "../Core/ButtonDisabled"

const PlanContainer = styled.div`
  padding-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 520px;
  height: 100%;
  display: flex;

  @media (min-width: ${breakpoints.lg}) {
    max-width: none;
    padding-bottom: 10px;
  }
`;

const StyledPlan = styled.div`
  background: white;
  display: block;
  text-align: center;
  width: 100%;
  height: 100%;
  border: 1px solid ${colors.borderGrey};
  border-radius: 8px;
`;

const PlanContent = styled.div`
  border-top: none;
  padding: 40px 0 54px;
`;

const Price = styled.div`
  color: ${colors.green};
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  flex-wrap: wrap;
`;

const PriceContainer = styled.div`
  ${fonts.montserratMedium};
  margin-bottom: 30px;
  span {
    font-size: 14px;
    color: ${colors.textGrey};
    line-height: 23px;
  }
`;

const PlanInfo = styled.div`
  ${fonts.montserratMedium};
  color : ${colors.black};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  
  div {
    b {
      ${fonts.montserratBold};
      font-size:20px;
      line-height: 25px;
      display: block;
      margin-bottom: 4px;
    }
    span {
      font-size: 14px;
      line-height: 25px;
    }
    margin-bottom: 25px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  
  @media (min-width: ${breakpoints.lg}) {
    margin-top: 40px;
    min-height: 220px;
  }
`;

const TitleContainer = styled.div`
  border-bottom: 1px solid ${colors.borderGrey};
  background: ${colors.greyLightPanel};
  padding: 20px 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  h2 {
    margin: auto;
  }
`;

const PlanData = styled.div`
  position: relative;
`;

// const Additional = styled.span`
//   ${fonts.montserratMedium};
//   color: ${colors.textGrey};
//   font-size: 14px;
//   line-height: 25px;
//   position: relative;
//   display: block;
//   clear: both;
//   width: 100%;
// `;

const ButtonContainer = styled.div`
  margin: 40px 50px 0;
  button {
    width: 100%;
    height: 60px;
    font-size: 16px;
    line-height: 19px;
  }
`;

const Plan = ({
  name,
  price,
  code,
  isCurrent,
  selectCopy,
  selectPlan,
  first,
}) => {
  const planInfo = {
    starter: [{deployments: "Unlimited", minutes: 50}],
    pro: [{deployments: "Unlimited", minutes: 250}],
    team: [{deployments: "Unlimited", minutes: 500}],
  };

  return (
    <PlanContainer first={first}>
      <StyledPlan>
        <TitleContainer>
          <h2>{name}</h2>
        </TitleContainer>
        <PlanContent>
          <PriceContainer>
            <Price>
              {price}
            </Price>
          </PriceContainer>
          <PlanData>
            {planInfo[code] &&
              planInfo[code].map((info, index) => {
                return (
                  <PlanInfo key={index}>
                    <div>
                      <b>{info.deployments}</b>
                      <span>Deployments</span>
                    </div>
                    <div>
                      <b>{info.minutes}</b>
                      <span>Deployment minutes</span>
                    </div>
                    {code === "team" &&
                    <div>
                      <b>Branch selection</b>
                      <span>Production and preview branches configuration</span>
                    </div>}
                  </PlanInfo>
                )
              })}
          </PlanData>
          <ButtonContainer>
            {isCurrent === true ? (
              <ButtonDisabled>Current plan</ButtonDisabled>
            ) : (
              <ButtonGreen onClick={(e) => selectPlan(e, code)}>
                {selectCopy}
              </ButtonGreen>
            )}
          </ButtonContainer>
        </PlanContent>
      </StyledPlan>
    </PlanContainer>
  )
}

export default Plan
