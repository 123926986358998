import React from "react"
import styled from "styled-components";
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"


const ProgressBar = styled.div`
  margin-bottom: 20px;
  display: flex;
  margin-right: 40px;
  :last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }
  @media (min-width: 375px) {
    margin-bottom: 0;
  }
`

const Percent = styled.div`
  width: 60px;
  height: 60px;
  margin-right: 14px;
  svg {
    width: 60px;
    height: 60px;
    transform: rotate(-90deg)
  }

`

const Circle = styled.circle`
  width: 60px;
  height: 60px;
  fill: none;
  stroke-width: 10;
  transform: translate(5px, 5px);
  stroke-dasharray: 157;
  stroke: ${props => props.value ? colors.greyDark : props.percentage && props.percentage > 0.75 ? "red" : props.percentage > 0.5 ? "orange" : colors.green};
  stroke-dashoffset: ${props => props.value ? `calc(157 - (157 * ${props.usage}) / ${props.max})` : 0};
`

const UsageInfo = styled.div`
  width: 85px;
  span {
    font-size: 12px;
    line-height: 15px; 
  }
`

const UsageAmount = styled.div`
  ${fonts.montserratMedium};
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 4px;
  b {
    ${fonts.montserratBold};
    font-size: 20px;
    line-height: 24px;
  }
`

const UsageGraph = ({deployments, minutes, max, usage}) => {
  let percentage;
  let usageValue;
  let maxValue;
  if (max === -1) {
    percentage = 0;
    usageValue = 0;
    maxValue = 100;
  } else {
    percentage = usage / max;
    usageValue = usage;
    maxValue = max;

    if (usageValue >= maxValue) {
      usageValue = 100
    }
  }

  return (
    <ProgressBar>
      <Percent>
        <svg>
          <Circle cx="25" cy="25" r="25" percentage={percentage}/>
          <Circle cx="25" cy="25" r="25" usage={usageValue} max={maxValue} value/>
        </svg>
      </Percent>
      <UsageInfo>
        <UsageAmount>
          <b>{usage}</b>/{max === -1 ? "∞" : max}
        </UsageAmount>
        {deployments ? <span>Number of deployments</span> : <span>Deployment minutes</span>}
      </UsageInfo>
    </ProgressBar>
  )
}

export default UsageGraph
