import React from "react"
import styled from "styled-components"
import Plan from "./Plan";
import breakpoints from "../../styles/breakpoints";

const PlansContainer = styled.div`
  @media(min-width: ${breakpoints.lg}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: stretch;
  }
`;

const PlanContainer = styled.div`
  width: 100%;

  @media(min-width: ${breakpoints.lg}) {
    width: 32%;    
  }
`;

const Plans = ({plans, subscription, selectPlan}) => {
  return (
    <PlansContainer>
      {plans && plans.map((plan, index) => {
        const {displayName, price, code} = plan;

        if (code === "free") {
          return;
        }

        const isCurrent = plan.code === subscription.code;

        return (
          <PlanContainer key={index}>
            <Plan name={displayName}
                  price={`$${price} / month`}
                  code={code}
                  isCurrent={isCurrent}
                  selectCopy={`Select plan`}
                  selectPlan={selectPlan}
                  first={index === 0}/>
          </PlanContainer>
        )
      })}
    </PlansContainer>
  );
};

export default Plans;
