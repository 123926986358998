const getFormattedDate = (date) => {
  let formattedDate;

  const displayDateData = new Date(date);

  formattedDate = new Intl.DateTimeFormat("en-US", {
    dateStyle: 'long',
  }).format(displayDateData);

  return formattedDate;
};

export {
  getFormattedDate,
}
