import React, {useEffect, useState} from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Core/Seo"
import plan from "../backend/plan";
import PrivateRoute from "../router/PrivateRoute";
import PageData from "../components/Core/PageData";
import Plans from "../components/Account/Plans";
import styled from "styled-components";
import Intro from "../components/Core/Intro/Intro";
import IntroTitle from "../components/Core/Intro/IntroTitle";
import fonts from "../styles/fonts"
import UsageGraph from "../components/Account/UsageGraph"
import breakpoints from "../styles/breakpoints"
import PageTitle from "../components/Core/PageTitle"
import {getFormattedDate} from "../services/date";
import IntroContent from "../components/Core/Intro/IntroContent";

const PlanInfo = styled.div`
  max-width: 450px;
  
  b {
    ${fonts.montserratMedium};
  }
`;

const UsageTitle = styled.div`
  ${fonts.montserratBold};
  font-size: 14px;
  line-height: 23px;
  margin-bottom: 14px;
`;

const UsageData = styled.div`
  margin-top: 20px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 0;
  }
`;

const GraphContainer = styled.div`
  @media (min-width: 375px) {
    display: flex;
  }
`;

const TitleContainer = styled.div`
  min-width: 100%;
  h1 {
    margin-bottom: 10px;
  }
`;

const AccountPage = () => {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    updatePageData();
  }, []);

  const updatePageData = () => {
    plan.getPlan()
      .then(({data}) => {
        setPageData(data);
      })
      .catch(() => {
        // alert("Error! Please try again");
      });
  };

  const selectPlan = (e, code) => {
    e.preventDefault();

    setPageData(null);

    plan.updatePlan(code)
      .then(({data}) => {
        const {url, code} = data;

        if (code === "free") {
          window.location.reload();
        } else if (typeof url === "undefined") {
          // alert("Error! Please try again.");

          window.location.reload();
        } else if (url) {
          window.location.href = url;
        } else {
          window.location.reload();
        }
      });
  };

  const hasData = pageData !== null;

  const subscription = pageData?.subscription;
  const plans = pageData?.plans;
  let usage = pageData?.usage;
  // const isDevStore = pageData?.isDevStore;

  let maxDeployments = -1;
  let maxMinutes = -1;

  plans && plans.map((plan) => {
    if (plan.code === subscription.code) {
      maxDeployments = plan.deploymentCountLimit;
      maxMinutes = plan.deploymentMinutesLimit;
    }
  });

  if (!usage) {
    usage = {
      deploymentCount: 0,
      deploymentMinutes: 0
    }
  }

  return (
    <Layout>
      <SEO title="Account"
           description="Current plan and upgrade options"/>
      <PageData hasData={hasData}>
        {/*{isDevStore && <p>The App is Free to use on development stores!</p>}*/}
        <Intro>
          <IntroTitle center>
            <TitleContainer>
              <PageTitle>Account</PageTitle>
              <PlanInfo>
                <p>You are currently on the <b>{subscription && subscription.displayName} plan</b>.</p>
                {(subscription && subscription.code === 'trial') &&
                <>
                  <p>You have unlimited deployments during your free Trial.
                    Your trial expires on Saturday, {getFormattedDate(subscription.trialExpiry)} and you will then be moved to the limited Free plan.</p>
                </>}
              </PlanInfo>
            </TitleContainer>
          </IntroTitle>
          <IntroContent auto>
            {usage &&
            <UsageData>
              <UsageTitle>Current usage {subscription && `(${subscription.displayName} plan)`}</UsageTitle>
              <GraphContainer>
                <UsageGraph usage={usage.deploymentCount} max={maxDeployments} deployments/>
                <UsageGraph usage={usage.deploymentMinutes} max={maxMinutes} minutes/>
              </GraphContainer>
            </UsageData>}
          </IntroContent>
        </Intro>
        <Plans plans={plans}
               subscription={subscription}
               selectPlan={selectPlan}/>
      </PageData>
    </Layout>
  )
};

function accountPageWrapper(props) {
  return <PrivateRoute component={AccountPage} {...props}/>
}

export default accountPageWrapper;
